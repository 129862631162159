<template>
  <div class="generic-card">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style scoped>
.generic-card {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  color: #888;
  padding: 3em;
}
</style>
