<template>
  <ion-page>
    <ion-content>
      <div class="scroll-container">
        <page-header />
        <generic-card>
          <div v-if="loadingStatus">
            ...
          </div>
          <div v-else>
            <div v-if="activationSuccessful">
              <h2 class="card-title">
                Your account has been reactivated successfully!
              </h2>
              <p>
                Proceed to <a href="http://app.dlvr.ng">app.dlvr.ng</a> to begin
                managing your deliveries.
              </p>
              <p>Here’s the URL to your customer booking form</p>
              <div class="link-container">
                agent.dlvr.ng/<span class="highlight">{{ slug }}</span
                >/book
              </div>
              <generic-button href="http://app.dlvr.ng"
                >Go to dashboard</generic-button
              >
            </div>
            <div v-if="!activationSuccessful">
              <h2 class="card-title">
                '( Activation of your account was unsuccessful
              </h2>
              <p>
                Contact support@dlvr.ng if you have questions regarding
                reactivation
              </p>
            </div>
          </div>
        </generic-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';

import PageHeader from '@/components/PageHeader.vue';
import GenericCard from '@/components/GenericCard.vue';
import GenericButton from '@/components/GenericButton.vue';
import { getLoading, openToast } from '@/composables/ionicControllers';
import { getConfig } from '@/lib/constants';

export default defineComponent({
  components: {
    PageHeader,
    GenericCard,
    GenericButton,
  },
  setup() {
    const activationSuccessful = ref(false);
    const loadingStatus = ref(false);

    const route = useRoute();
    const slug = route.query.id;
    const code = route.query.code;

    const reactivateAccount = async () => {
      if (slug && code) {
        const loading = await getLoading();
        loadingStatus.value = true;
        await loading.present();
        try {
          const result = await axios.get(
            `${
              getConfig().FIREBASE_WEBHOOKS_URL
            }/merchants/reactivateMerchant?slug=${slug}&code=${code}`,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          );

          if (result.status !== 200) {
            await openToast(
              'There was an error while trying to reactivate your account',
            );
            activationSuccessful.value = false;
          } else {
            activationSuccessful.value = true;
          }
        } finally {
          loadingStatus.value = false;
          await loading.dismiss();
        }
      }
    };

    onMounted(reactivateAccount);

    return {
      slug,
      activationSuccessful,
      loadingStatus,
    };
  },
});
</script>

<style scoped>
.link-container {
  font-family: 'Source Code Pro', monospace;
  padding: 1.5em;
  background: #f6f6f6;
  margin: 2em 0;
}

.link-container .highlight {
  font-weight: 700;
  color: #2a90e1;
}
</style>
