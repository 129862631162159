
import { defineComponent } from 'vue';
import { IonImg, IonRow, IonCol, IonGrid } from '@ionic/vue';

export default defineComponent({
  components: {
    IonImg,
    IonRow,
    IonCol,
    IonGrid,
  },
  props: {
    logo: {
      type: String,
      default: '/assets/dlvr-logo.png',
    },
  },
});
