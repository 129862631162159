import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2461a05c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-header" }
const _hoisted_2 = { class: "logo-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_grid, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_row, { class: "ion-align-items-center" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, {
              size: "12",
              "size-lg": "2"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  (_ctx.logo)
                    ? (_openBlock(), _createBlock(_component_ion_img, {
                        key: 0,
                        src: _ctx.logo
                      }, null, 8, ["src"]))
                    : (_openBlock(), _createBlock(_component_ion_img, {
                        key: 1,
                        src: "/assets/dlvr-logo.png"
                      }))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_col, { "size-lg": "10" }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              _: 3
            })
          ]),
          _: 3
        })
      ]),
      _: 3
    })
  ]))
}