<template>
  <ion-button :color="color" :href="href">
    <slot></slot>
  </ion-button>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    href: String,
    color: String,
  },
});
</script>
